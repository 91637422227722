
import {Component, Vue} from 'vue-property-decorator';
import packageJson from '../package.json';

@Component({
  head: {
    title() {
      return {
        inner: 'Csc',
        complement: 'Home'
      };
    },
    link() {
      return [
        {rel: 'stylesheet', h: 'assets/specific.css', id: 'specific'},
        {rel: 'shortcut icon', h: 'assets/favicon.ico', id: 'favicon'}
      ];
    }
  },
})
export default class App extends Vue {
  public projectVersion = packageJson.version;
  public darkTheme = false;
}
