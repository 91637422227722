import Vue from "vue";
import App from "./App.vue";
import router from "../router";
import Vuetify from "vuetify";
import en from "vuetify/src/locale/en";
import fr from "vuetify/src/locale/fr";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import '@mdi/font/css/materialdesignicons.css'
import "vuetify/dist/vuetify.min.css";
import VueHead from 'vue-head';
import Vuex from 'vuex';
import VuexI18n from 'vuex-i18n';
import VueI18nDirectives from 'vue-i18n-directives';
import I18nService from './services/i18n';

Vue.use(Vuetify);

const vuetifyOpts = {
    lang: {
        locales: {en, fr},
        current: "en"
    }
};

Vue.filter("date", (val: any) => {
    const date = new Date(Date.parse(val));
    return date.toLocaleDateString() + " at " + date.toLocaleTimeString();
});

Vue.use(Vuex);

declare module 'vue/types/vue' {
    interface Vue {
        $languages: any;
    }
}

const store = new Vuex.Store({});
Vue.use(VuexI18n.plugin, store, {
    onTranslationNotFound(locale: string, key: string) {
        console.warn(`i18n :: Key '${key}' not found for locale '${locale}'`);
    },
});
Vue.use(VueI18nDirectives);
Vue.use(I18nService);
Vue.use(VueHead);

Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'development') {
    const VueAxe = require('vue-axe').default
    Vue.use(VueAxe)
}

new Vue({
    router,
    render: (h: any) => h(App),
    vuetify: new Vuetify(vuetifyOpts)
}).$mount("#app");
