import Vue from 'vue';
import Router from 'vue-router';

const SelectSso = () => import('./src/views/SelectSso.vue')
const GetConsent = () => import('./src/views/GetConsent.vue');
const Error = () => import('./src/views/Error.vue');

Vue.use(Router);

export default new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/csc/select-sso',
            name: 'select-sso',
            component: SelectSso

        },
        {
            path: '/csc/consent',
            name: 'consent',
            component: GetConsent,
            props: {numPage: 1},

        },
        {
            path: '/csc/validate-info',
            name: 'consent',
            component: GetConsent,
            props: {numPage: 2}
        },
        {
            path: '/csc/error',
            name: 'error',
            component: Error
        }
    ],
});
